import React from 'react';

import * as styles from './TextBlock.module.css';
import { Button } from './Button';

export const TextBlock = ({ data }) => {
  const { title, text, link, linkLabel } = data;

  if (title && text) {
    return (
      <section className={styles.TextBlock}>
        <h2>{title}</h2>
        <p>{text}</p>
        <Button to={link} color="darkbg">
          {linkLabel}
        </Button>
      </section>
    );
  }
  return null;
};
