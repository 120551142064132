import * as React from 'react';
import { graphql } from 'gatsby';

import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import TeaserBoxes from '@mangoart/gatsby-ui/components/ezagrar/MeinTraktor-Lamborghini-Same/TeaserBoxes/TeaserBoxes';
import { TextBlock } from '@mangoart/gatsby-ui/components/ezagrar/TextBlock';
import Slider from '@mangoart/gatsby-ui/components/ezagrar/MeinTraktor-Lamborghini-Same/Slider/Slider';
import DefaultLayout from '../layout/DefaultLayout';

const IndexPage = ({ data, location }) => {
  const { siteData, pageData } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph, teaserboxes, textblock, slides } = pageData;
  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <Slider data={slides} />
      <TeaserBoxes data={teaserboxes.filter((item) => item.published)} />
      <TextBlock data={textblock} />
    </DefaultLayout>
  );
};

export default IndexPage;

export const IndexPageQuery = graphql`
  query IndexPageQuery {
    siteData: site {
      id
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    pageData: cockpitPageStartseite {
      textblock {
        title
        text
        link
        linkLabel
      }
      slides {
        id
        title
        image {
          childImageSharp {
            gatsbyImageData(width: 1600, backgroundColor: "#fff", quality: 80)
          }
        }
        text_line_1
        text_line_2
        mobile_text_line_1
        mobile_text_line_2
        highlighted_line
        position
        text_color
      }
      teaserboxes {
        id
        title
        teaser
        link
        image {
          childImageSharp {
            gatsbyImageData(width: 640, transformOptions: { fit: COVER }, backgroundColor: "#fff")
          }
        }
        alttext
        actionlabel
        published
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            gatsbyImageData(width: 1300, height: 630)
          }
        }
      }
    }
  }
`;
