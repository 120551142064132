import React from 'react';
import clsx from 'clsx';

import TeaserBox from './TeaserBox';

import * as styles from './TeaserBoxes.module.css';

function TeaserBoxes({ data }) {
  return (
    <section className={clsx(styles.TeaserBoxes, 'defaultWidth')}>
      {data.map((node) => (
        <TeaserBox data={node} key={node.title} />
      ))}
    </section>
  );
}

export default TeaserBoxes;
