// extracted by mini-css-extract-plugin
export var carousel = "Slider-module--carousel--aN2Q8";
export var display = "Slider-module--display--AjxI6";
export var innerWrapper = "Slider-module--innerWrapper--N7Q54";
export var lowerLeft = "Slider-module--lowerLeft--LOJdg";
export var lowerRight = "Slider-module--lowerRight--0dSck";
export var slideText = "Slider-module--slideText--VvjMw";
export var slideTextContainer = "Slider-module--slideTextContainer--2GWGT";
export var textBlack = "Slider-module--textBlack--UtpLp";
export var textWhite = "Slider-module--textWhite--BrwGA";
export var upperLeft = "Slider-module--upperLeft--PHWBe";
export var upperRight = "Slider-module--upperRight--+vED7";